
/* Container for the entire calculator */
.calculator-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.calculator-container 
h1 {
  font-size: 1.5em;
  margin-bottom: 20px;

}

/* Input fields */
.input-container {
  display: flex;
  margin-bottom: 10px;
}

.cf-card {
  flex: 1;
  padding: 0 25px;
  position: relative;
}

.cf-card::before {
  content: "";
  background-image: url('./assets/images/logo.png');
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.cf-card 
.ant-statistic-title {
  font-size: 0.9em;
  margin-bottom: 0;
  margin-top: 5px;
}

.cf-card 
.ant-statistic-content {
  font-size: 1.5em;
  margin-top: -6px;
  padding: 0;
}

.correction-factor 
.ant-statistic-title {
  font-size: 1.5em;
  margin-bottom: 0;
  margin-top: 5px;
  color: brown;
}

.correction-factor 
.ant-statistic-content {
  font-size: 3em;
  color: brown;
}

.footnote {
  font-size: 0.7em;
  color:darkgray;
}